<template>
  <div class="container text-center">
    <h1>404</h1>
    <router-link to="/">Back to home</router-link>
  </div>
</template>

<script>
export default {
  name: "NotFound",
};
</script>
